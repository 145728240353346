import { QuestionAndAnswerType } from '@/components/LandingMozza/common/Faq/types';

const config: QuestionAndAnswerType[] = [
  {
    question: `Qu'est-ce que la plateforme "Les Sherpas" ?`,
    answer:
      'Créée en 2017, Les Sherpas est une marketplace innovante de cours particuliers, proposant des sessions en ligne et à domicile. La plateforme connecte étudiants et parents à des Sherpas, synonymes de professeurs particuliers hautement qualifiés. Au-delà de la mise en relation, avec son blog, sa chaine Youtube et ses réseaux sociaux, Les Sherpas se distingue en tant que média étudiant, fournissant des fiches pratiques et des conseils précieux pour la réussite académique.',
  },
  {
    question: 'Qui sont les professeurs chez Les Sherpas ?',
    answer: `Nos plus de 3000 professeurs particuliers, majoritairement issus des meilleurs établissements, offrent des cours bienveillants et experts. En connectant des élèves de toute la France avec ces enseignants d'exception, la plateforme garantit une qualité d'enseignement et une pédagogie remarquable. Nos Sherpas bénéficient également d'une formation assurée par notre équipe, visible grâce au badge "Certifié" sur leur profil.`,
  },
  {
    question: 'Quelles matières sont enseignées chez Les Sherpas ?',
    answer: `Les Sherpas propose un large éventail de cours couvrant les sciences (comme les mathématiques, la physique-chimie), les lettres (français, philosophie, anglais...), les sciences humaines (histoire-géographie, sociologie...) et bien d'autres spécialités pointues. La plateforme offre également des sessions de méthodologie, d'orientation scolaire, et de préparation pour divers examens et concours. Au total, ce sont pas moins de 50 matières qui sont proposées, quel que soit le niveau de l'élève.`,
  },
  {
    question:
      'Pourquoi opter pour des cours en visioconférence chez Les Sherpas ?',
    answer: `Avec Les Sherpas, les cours en visioconférence allient interactivité, flexibilité et commodité. Évitez les déplacements, économisez du temps et profitez de l'option d'enregistrement des leçons. Grâce à des outils modernes, nos Sherpas rendent chaque cours en ligne particulièrement efficace.`,
  },
  {
    question: 'Comment sont fixés les tarifs des cours chez Les Sherpas ?',
    answer: `Les Sherpas propose des cours dès 15€/h, avec des tarifs définis par chaque professeur selon son expérience, la matière et le niveau enseigné. Les prix reflètent également les avis des élèves et le marché. Certains tarifs peuvent être dégressifs en fonction de la durée du suivi. Le tarif moyen affiché varie entre 15€ et 89€/heure, incluant la commission la plus faible des agences de soutien scolaire : en moyenne 35% contre 200%. Profitez d'un cours d'essai offert et d'une garantie satisfait ou remboursé.`,
  },
  {
    question:
      'Quand est le meilleur moment pour prendre des cours particuliers avec Les Sherpas ?',
    answer: `Contrairement aux idées reçues, les cours particuliers chez Les Sherpas sont ouverts à tous, et pas seulement aux élèves en difficulté. Que ce soit pour éclaircir des concepts en maths, maîtriser la grammaire française ou exceller en anglais tant à l'écrit qu'à l'oral, le soutien scolaire est un atout. Choisissez un accompagnement régulier ou ponctuel, adapté à vos besoins. Profitez-en les soirs, week-ends ou pendant les vacances pour améliorer votre niveau et faire grimper vos résultats scolaires !`,
  },
];

export default config;
